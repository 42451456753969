<template>
  <div class="card px-lg-20 px-5 market-reports-page">
    <h1>{{ $t('No market reports yet...') }}</h1>
    <inline-svg class="me-3" src="/media/buying/icons/file.svg" />
    <p>{{ $t('You have not received any market reports yet. We will let you know once they arrive!') }}</p>
    <p>{{ $t('Market reports is based on your bank(s) and product area(s) selection.') }}</p>
    <router-link to="/business/reports/custom-reports" class="btn btn-outline-primary" id="custom-report">
      {{ $t('Generate Custom Report') }}
    </router-link>

    <div class="bank border-bottom py-5 d-flex justify-content-between align-items-center" v-if="false">
      <div>
        <h3>Best Bank - Denmark</h3>
        <div class="d-flex mt-5">
          <img src="/media/smiles/happy.png" width="24" height="24" alt="">
          <p class=" border-end px-3 fs-6 text-gray-500">Axees Bank</p>
          <p class="px-5 fs-6 text-gray-500">Created 2hr ago</p>
        </div>
      </div>
      <div class="text-center">
        <inline-svg class="me-3" src="/media/buying/icons/file.svg" />
        <router-link to="" class="text-primary d-block">{{$t('View')}}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";

export default {
  name: "MarketReports",
  mounted() {
    setCurrentPageBreadcrumbs({
      title: this.$t('Market Reports'),
      subTitle: this.$t('A subtext line to explain what the title means'),
    })
  },
};
</script>
<style lang="scss">
.market-reports-page {
  padding: 74px 50px 61px 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #B3B3B3;
  }
  svg {
    width: 68px;
    height: 80px;
    margin-top: 40px;
    margin-bottom: 53px;
    path {
      fill: #B3B3B3;
    }
  }
  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 19px;
    text-align: center;
    color: #8B8B8B;
  }
  a#custom-report {
    border: 1px solid #435BF4;
    border-radius: 30px;
    font-weight: 700;
    font-size: 16px;
    padding: 12px 70px;
    margin-top: 50px;
  }
}
</style>
